.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // Navigation padding
  padding: 0 1rem;
  padding-top: #{'calc(7rem + 4vw)'};
  padding-bottom: #{'calc(2rem + 4vw)'};

  .footerNav {
    margin-top: 2rem;

    a {
      &:hover {
        text-decoration: underline;
      }

      &:not(:last-child) {
        margin-right: 1rem;
      }

      @media screen and (max-width: 880px) {
          display: block;
          margin-bottom: .5rem;
          text-align: center;
      }
    }
  }
}
