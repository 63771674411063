@import '../../scss/variables.scss';

.checkbox {
  display: flex;

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;

    &:checked {
      ~ .checkBox {
        background-color: $cyan;

        &:after {
          visibility: visible;
        }
      }
    }
  }

  .checkBox {
    position: relative;
    width: 1rem;
    height: 1rem;
    border: 1px solid $cyan;
    border-radius: 25%;
    margin-top: .1rem;
    margin-right: .5rem;

    &:after {
      content: '';
      position: absolute;
      border: 2px solid #fff;
      border-top-width: 0;
      border-left-width: 0;
      left: 50%;
      top: 50%;
      margin-top: -1px;
      width: 3px;
      height: 6px;
      transform: translate(-50%, -50%) rotate(40deg);
      visibility: hidden;
    }
  }

  .label {
    flex: 1;
  }
}
