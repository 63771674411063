.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .logoWrapper {
    $logoWidth: 300px;
    $highlightSize: $logoWidth * .3;
    $wrapperWidth: $logoWidth + 2 * $highlightSize;
    $initialMaskPosition: -$logoWidth/2 - $highlightSize / 2;
    $endMaskPosition: $initialMaskPosition + $wrapperWidth;
    position: relative;
    width: $wrapperWidth;
    mask-image: linear-gradient(130deg, rgba(255, 255, 255, 1) calc(50% - #{$highlightSize/2}), rgba(255, 255, 255, .5) 50%, rgba(255, 255, 255, 1) calc(50% + #{$highlightSize/2}));
    mask-position: $initialMaskPosition;

    animation: highlightAnim 3s infinite linear;

    @keyframes highlightAnim {
      25%, 100% {
        mask-position: $endMaskPosition;
      }
    }

    .logo {
      margin: 0 $highlightSize;
    }
  }
}
