@import './variables.scss';

* {
  box-sizing: border-box;
}

body {
  margin: 0;

  &:before {
    z-index: -1;
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle, hsl(0, 0%, 17%), hsl(0, 0%, 8%));

    .lightBg & {
      background: hsl(0, 0%, 28%);
    }
  }
}

.isApp {
  user-select: none;
}

.isWebsite body:before {
  background: radial-gradient(circle, #fff, hsl(0, 0%, 85%));
}

a:focus,
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

a {
  color: inherit;
  text-decoration: none;
}

p a {
  color: $cyan;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

ul, ol {
  padding-left: 1.5rem;
  line-height: 1.4;
}

button {
  outline: none;
}

h1 {
  margin: 0;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

h2 {
  font-size: 1.1rem;

  &:after {
    content: '';
    display: block;
    height: 1px;
    margin-top: .3rem;
    background-color: hsl(0, 0, 75%);
  }
}
