.addVoucherModal {
  padding: 2rem;

  .codeInputWrapper {
    display: flex;
    align-items: flex-start;
    margin: 1rem 0;

    .codeInput {
      width: 15rem;
      margin-right: 1.5rem;
      margin-top: .25rem;
    }

    .generateCode {
      width: auto;
    }
  }

  .monthsValid {
    width: 15rem;
  }

  .saveButton {
    display: block;
    margin-top: 1rem;
    margin-left: auto;
  }
}
