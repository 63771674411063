@import '../../scss/variables.scss';

.spinner {
  margin: 0 auto;

  &.big {
    width: 2rem;
    height: 2rem;
  }

  &.small {
    width: 1rem;
    height: 1rem;
  }

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid $cyan;
    border-color: $cyan transparent $cyan transparent;
    animation: spinner 1.2s linear infinite;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
