@import '../../scss/variables.scss';

:global(.isApp) .navigation {
  border-radius: 0;
}

:global(.isWebsite) .navigation {
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 2rem);
  max-width: 800px;
  padding: 1rem;
  padding-right: 1.5rem;
  border-radius: 10rem;
  z-index: 1000;
}

.mainMenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;

  .logoMark {
    display: flex;
    height: 100%;
  }

  nav {
    margin: 0 1rem;

    a {
      position: relative;
      color: inherit;
      text-decoration: none;
      padding: .25rem 0;

      &:not(:last-child) {
        margin-right: 1rem;
      }

      &:hover {
        color: $cyan;
      }

      &.activeLink {
        color: $cyan;

        &:after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: -2px;
          width: 1.5rem;
          height: 2px;
          transform: translateX(-50%);
          background-color: $cyan;
        }
      }
    }
  }

  .spacer {
    flex: 1;
  }

  .langAndAccount {
    display: flex;
    gap: 1rem;

    .accountBtn {
      display: flex;
    }
  }
}

.subMenu {
  $dividerColor: hsl(0, 0, 75%);

  text-align: center;
  border-top: 1px solid $dividerColor;
  padding: .7rem;

  a {
    padding: .25rem 0;

    &.disabled {
      pointer-events: none;
    }

    &:not(:last-child) {
      margin-right: 1.25rem;

      &:after {
        content: '';
        display: inline-block;
        width: .6rem;
        height: .6rem;
        margin-left: .75rem;
        border: 2px solid $dividerColor;
        border-left-width: 0;
        border-bottom-width: 0;
        transform: rotate(45deg);
      }
    }

    &.activeLink {
      font-weight: bold;
    }
  }
}
