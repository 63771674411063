.subscribe {
  text-align: center;

  .paymentMethodsWrapper {
    display: inline-block;
    width: 20rem;
    margin: 1rem 0;

    .divider {
      display: flex;
      align-items: center;
      margin: 2rem 0;
      color: hsl(0, 0%, 50%);

      > span {
        margin: 0 .75rem;
      }

      &:after, &:before {
        content: '';
        flex: 1;
        height: 2px;
        margin-bottom: .1rem;
        background-color: hsl(0, 0%, 80%);
      }
    }

    .stripeButton {
      width: 100%;
      marginTop: .5rem;
      height: 45px;
    }

    .mollieSubscribeButton {
      width: 100%;
    }

    .voucherInputWrapper {
      display: flex;
      align-items: center;

      .voucherInput {
        flex: 1;
        margin-bottom: 0;
      }

      .voucherConfirm {
        margin-left: 1rem;
      }
    }
  }

  .errorMsg {
    color: #f00;
  }
}
