@import '../../scss/variables.scss';

.button {
  border: none;
  cursor: pointer;
  border-radius: 10rem;

  &:disabled {
    cursor: not-allowed;
  }

  &.filled {
    padding: .6rem 1.8rem;
    background: $cyan;
    color: #fff;

    &:hover {
      background: transparentize($cyan, .15);
    }

    &:disabled {
      background: hsl(0, 0%, 50%);
    }
  }

  &.link {
    color: $cyan;
    padding: .25rem 0;
    background: none;
  }
}
