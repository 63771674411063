.download {
  text-align: center;

  .buttonContent {
    display: inline-flex;
    align-items: center;

    .icon {
      height: 1em;
      fill: currentColor;
      margin-right: .3em;
    }
  }

  .primaryButton {
    margin-bottom: .4rem;
  }

  .secondaryButton {
    font-size: .9em;
  }

  .error {
    color: red;
  }
}
