@import '@app/scss/variables.scss';

.vouchersList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;

  .addVoucherButton {
    position: relative;
    appearance: none;
    background-color: $cyan;
    border: none;
    width: 2rem;
    height: 2rem;
    border-radius: 100%;

    &:hover {
      opacity: .8;
      cursor: pointer;
    }

    &:before, &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -1px;
      margin-top: -20%;
      width: 2px;
      height: 40%;
      background-color: #fff;
    }

    &:after {
      transform: rotate(90deg);
    }
  }
}
