.dataTable {
  overflow: auto;

  table {
    border-collapse: collapse;
  }

  th, td {
    padding: .75rem 1.5rem;
    padding-left: 0;

    &:first-child {
      padding-left: .5rem;
    }
  }

  $rowSeparatorColor: hsl(0, 0%, 70%);

  th {
    position: sticky;
    top: 0;
    text-align: left;
    padding-bottom: .25rem;
    background-color: #fff;

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $rowSeparatorColor;
    }
  }

  tr {
    &:not(:last-child) {
      td {
        border-bottom: 1px solid $rowSeparatorColor;
      }
    }

    .rowAction {
      visibility: hidden;
    }

    &:hover .rowAction {
      visibility: visible;
    }
  }

  &.clickable {
    tr:not(:first-child):hover {
      background: hsl(0, 0%, 90%);
      cursor: pointer;
    }
  }
}
