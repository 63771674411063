@import '../../scss/variables.scss';

.wrapper {
  position: relative;

  .select {
    width: 100%;
    border: none;
    border-bottom: 2px solid $cyan;
    background: none;
    padding: .25rem;
    padding-left: 0;

    &.compact {
      border-bottom: none;
    }
  }

  label {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    transition: transform .25s;
    transform-origin: left;
  }

  &.hasContent label,
  .select:focus ~ label {
    transform: translateY(-1rem) scale(.7);
    color: $cyan;
  }
}
