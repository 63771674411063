@import './variables.scss';

body,
textarea {
  font-family: 'Roboto', sans-serif;
}

body,
button,
textarea,
input,
select {
  font-size: 16px;

  .isApp & {
    color: $fontColor;
  }

  .isWebsite & {
    color: $fontColorLightMode;
  }
}
