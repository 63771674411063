.card {
  margin: 2rem;
  width: 100%;
  max-width: 420px;
  padding: 4rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 500px) {
    padding: 2rem;
  }

  .logo {
    margin: 0 1rem;
  }

  .form {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    margin-top: 3rem;

    button {
      align-self: center;
    }

    .error {
      color: red;
      text-align: center;
    }
  }

  .footerLinks {
    text-align: center;
    line-height: 1.6;
  }

}
