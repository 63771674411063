@import '../../scss/variables.scss';

.wrapper {
  position: relative;
  display: flex;
  margin-bottom: 1.5rem;
  border-bottom: 2px solid $cyan;

  input {
    flex: 1;
    background: none;
    border: none;
    outline: none;
    padding: .25rem 0;
    width: 100%;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance: textfield;
    }
  }

  label {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    transition: transform .25s;
    transform-origin: left;
  }

  &.hasContent label,
  input:focus ~ label {
    transform: translateY(-1rem) scale(.7);
    color: $cyan;
  }
}
