.toolBar {
  margin: 1rem 0;
  display: flex;
  gap: 2rem;

  .filters {
    display: flex;
    gap: 1rem;
  }
}
