$fontColor: hsl(0, 0%, 70%);
$fontColorLightMode: hsl(0, 0%, 33.33%);
$fontColorDark: hsl(0, 0%, 33.33%);
$cyan: #009EE2;

@mixin cardShadow {
  // Final vertical distance: 40px
  box-shadow:
    0 1.1px 2.2px rgba(0, 0, 0, 0.02),
    0 2.7px 5.3px rgba(0, 0, 0, 0.028),
    0 5px 10px rgba(0, 0, 0, 0.035),
    0 8.9px 17.9px rgba(0, 0, 0, 0.042),
    0 16.7px 33.4px rgba(0, 0, 0, 0.05),
    0 40px 80px rgba(0, 0, 0, 0.07);
}
