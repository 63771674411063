.account {
  position: relative;
  width: 100%;
  max-width: 600px;

  .tabs {
    margin-bottom: 1rem;
  }

  h2, .logoutButton {
    margin-top: 1.5rem;
  }

  .passwordChangeForm {
    max-width: 15rem;
  }

  .profileTable {
    margin-bottom: 1rem;

    td {
      padding: .25rem 0;
      padding-right: 1rem;
    }

    td:first-child {
      opacity: .6;
    }
  }

  .appVersion {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: inherit;
    display: flex;

    .version {
      opacity: .5;
    }

    .releaseInfoIcon {
      width: 1rem;
      margin-left: .35rem;

      &:hover {
        cursor: pointer;
        opacity: .7;
      }
    }
  }

  .invoicesTable {
    border-collapse: collapse;

    th, td {
      padding: .15rem 1.5rem;
      padding-left: 0;

      &:first-child {
        padding-left: .5rem;
      }
    }

    th {
      text-align: left;
      padding-bottom: .5rem;
    }
  }
}
