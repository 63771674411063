@import '@app/scss/variables.scss';

.admin {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2rem;
  max-height: 70%;

  .toolbar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;

    .tabs {
      flex: 1;
      max-width: 400px;
    }
  }
}
