@import '@app/scss/variables.scss';

.wrapper {
  width: 100%;
  max-width: 800px;

  .introCard {
    padding: 2rem;
    margin-bottom: 2rem;

    h2 {
      margin-top: 1.5rem;
      margin-bottom: .5rem;
      font-size: 1.1rem;

      &::after {
        content: none;
      }
    }

    .steps {
      list-style-type: none;
      padding-left: 0;
      margin: 0;

      .step {
        display: inline-block;

        &:not(:last-child) {
          margin-right: .5rem;

          &::after {
            content: '';
            display: inline-block;
            width: .5rem;
            height: .5rem;
            margin: 0 .5rem;
            border: 1px solid $cyan;
            border-width: 0 0 2px 2px;
            transform: rotate(225deg);
          }
        }
      }

      @media screen and (max-width: 800px) {
        list-style-type: decimal;
        padding-left: 1rem;

        .step {
          display: list-item;

          &::after {
            display: none !important;
          }
        }
      }
    }

    button {
      margin-top: 2rem;
    }
  }

  .videoCard {
    position: relative;
    padding: 0;
    overflow: hidden;
    // Safari fix
    align-items: flex-start;
    margin-bottom: 2rem;

    &::before {
      content: '';
      position: relative;
      display: block;
      padding-top: 56.25%;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.manualLinks {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    margin-top: .2rem;

    &:not(:first-child) {
      margin-left: .5rem;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
