.modalBackground {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(#000000, .5);
}

.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .closeButton {
    appearance: none;
    border: none;
    background: none;
    padding: 0;
    position: absolute;
    $size: 1.5rem;
    top: .5rem;
    right: .5rem;
    width: $size;
    height: $size;
    background-color: hsl(0, 0%, 10%);
    border-radius: 100%;
    cursor: pointer;

    &:hover {
      background-color: transparent;
    }

    &:before, &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 25%;
      height: 50%;
      width: 2px;
      margin-left: -1px;
      margin-top: -.5px;
      background-color: hsl(0, 0%, 50%);
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}
