@import '../../scss/variables.scss';

.wrapper {
  display: flex;
  border-bottom: 1px solid $cyan;

  .tab {
    flex: 1;
    padding: .35rem;
    text-align: center;
    border-bottom: 0px solid $cyan;

    &:not(.disabled):hover {
      background: hsla(0, 0%, 0%, .1);
      cursor: pointer;
    }

    &.current {
      font-weight: bold;
      border-bottom-width: 1px;
    }

    &.disabled {
      opacity: .5;
      cursor: not-allowed;
    }
  }
}
