@import '@app/scss/variables.scss';

.userDetailsModal {
  padding: 2rem;

  .name {
    margin-bottom: .25rem;
    font-weight: bold;
    font-size: 1.25rem;
  }

  .company {
    opacity: .8;
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }

  .tablesWrapper {
    display: flex;
    align-items: baseline;
    gap: 2rem;

    @media screen and (max-width: 1000px) {
      display: block;
    }
  }

  .table {
    th {
      text-align: left;
      vertical-align: top;
      white-space: nowrap;
    }

    th, td {
      padding: .35rem 1rem;
      padding-left: 0;

      a {
        color: $cyan;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .okButton {
    display: block;
    margin-top: 1rem;
    margin-left: auto;
  }
}
