.billingAddressTable {
  margin-bottom: 1rem;

  td {
    padding: .25rem 0;
    padding-right: 1rem;
  }

  td:first-child {
    opacity: .6;
  }
}

.editingForm {
  margin-top: 2rem;
  max-width: 300px;

  .select {
    margin-bottom: 1.5rem;
  }
}
