.releaseNotesModal {
  width: 800px;
  max-height: 80%;
  padding: 2rem;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    overflow: auto;
    margin: 1rem 0;
  }

  .actionButtons {
    display: flex;
    justify-content: space-between;
  }
}
